<template>
  <div class="rightLink">
    <div class="bottomDiv" style="height:700px">
      <div class="title">分账统计</div>
      <div class="formDiv" style="width: 70vw">
        <el-form label-position="left" label-width="60px">
          <el-row :gutter="20">
            <el-col :span="2"></el-col>
            <!-- <el-col :span="6">
                <el-form-item label="编号:">
                  <el-input
                    v-model="FormData.id"
                    placeholder="请输入编号"
                  ></el-input>
                </el-form-item>
              </el-col> -->

            <el-col v-show="isadmin" :span="6">
              <el-form-item label="运营商:">
                <el-select
                  v-model="username"
                  placeholder="请选择运营商"
                  style="width: 100%"
                  clearable
                  @change="changeUser"
                >
                  <el-option
                    v-for="aa in users"
                    :key="aa.username"
                    :value="aa.username"
                    :label="aa.username"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="时间:" class="time_pick">
                <el-date-picker
                  style="height: 40px; width: 100%"
                  v-model="FormData.orderTime"
                  type="daterange"
                  unlink-panels
                  range-separator=" - "
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  size="small"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <div class="BtnSearch" @click="searchBtn()">查询</div>
            </el-col>
            <el-col :span="1" style="margin-left:30px">
              <Data2Excel api='/report/accountcount' :data="dataa" fileName="分账统计"></Data2Excel>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 表格部分 -->

      <div class="tableDiv">
        <!-- <div class="pagination">
          <div class="flex1">
            <p s>
              日期：<span>{{ thisday }}</span>
            </p>
            <p>
              今日分账总金额(元)：<span>{{
                tableData1.pay_money ? tableData1.pay_money.toFixed(2) : 0.0
              }}</span>
            </p>
          </div>
        </div> -->

        <el-table
          ref="multipleTableRef"
          :data="tableData"
          border
          v-loading="loading"
          height="510px"
          style="width: 90%; margin: 20px auto; overflow-y: auto"
        >
          <el-table-column label="序号" type="index" align="center" width="60">
            <template #default="{ $index }">
              {{ (currentPage - 1) * pageSize + $index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="日期" prop="date" />
          <el-table-column label="运营商" prop="username" />

          <!-- <el-table-column
            label="月份"
            prop="month"
          />
          <el-table-column
            label="日期"
            prop="day"
          /> -->
          <el-table-column
            label="金额(元)"
            prop="total_sum"
            :formatter="rounding"
          />
        </el-table>

        <div class="pagination">
        <div class="flex1">
            <p>
              分账总次数：<span>{{ tableData1.count_data?tableData1.count_data:0 }}</span>
            </p>
            <p>
              分账总收入(元)：<span>{{
                tableData1.sum_money ? tableData1.sum_money : "0.00"
              }}</span>
            </p>
          </div>
        <el-pagination
          class="fenye"
          :current-page="currentPage"
          :page-size="pageSize"
          :small="small"
          layout="total, slot, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
          <template #default>
            <span class="el-pagination__total">{{ Math.ceil(total / pageSize) }} 页 </span>
          </template>
        </el-pagination>
        </div>

        <!-- 退费弹窗 -->
      </div>
    </div>
  </div>
</template>
  <script>
import { onMounted, reactive, toRefs } from "vue-demi";
import { report_accountcount, operator_data } from "@/request/api";
import { ElMessage } from "element-plus";
import Data2Excel from "@/component/Data2Excel.vue"
export default {
  name: "SearchMan",
  components:{
    Data2Excel
  },
  setup() {
    const data = reactive({
      //表单数据
      carList: [
        {
          value: 2,
          label: "电动车",
        },
        {
          value: 4,
          label: "电动汽车",
        },
      ],
      car: "",
      users: "",
      FormData: {
        orderTime: ["", ""],
        username: localStorage.getItem("username"),
      },
      tableData: [],
      tableData1: {},
      isadmin: false,

      //   分页
      currentPage: 1,
      pageSize: 20,
      total: 0,
      checked: 0,
      small: true,
      thisday: "",
      username:"",
      loading:false,
      dataa:{}
    });
    const rounding = (row, column) => {
      return parseFloat(
        row[column.property] ? row[column.property] : "0"
      ).toFixed(2);
    };
    const formatDate = (row, column) => {
      // 获取单元格数据
      let data = row[column.property];
      if (data == null) {
        return null;
      }
      let dt = new Date(data);
      return (
        dt.getFullYear() +
        "-" +
        (dt.getMonth() + 1) +
        "-" +
        dt.getDate() +
        " " +
        dt.getHours() +
        ":" +
        dt.getMinutes() +
        ":" +
        dt.getSeconds()
      );
    };
    const searchBtn = () => {
      data.currentPage = 1;
      getList();
    };
const changeUser = (val) => {
      if (val) {
        data.FormData.username = val;
      } else if (val == "" || val == null || val == undefined) {
        data.FormData.username = sessionStorage.getItem("name");
      }
    };
    const getList = () => {
      var day = new Date();
      data.thisday =
        day.getFullYear() +
        "-" +
        (day.getMonth() + 1).toString().padStart(2, 0) +
        "-" +
        day.getDate().toString().padStart(2, 0);
      console.log(data.thisday, "今日日期1");
      const stDate = data.FormData.orderTime[0]
        ? data.FormData.orderTime[0]
        : undefined;
      const endDate = data.FormData.orderTime[1]
        ? data.FormData.orderTime[1]
        : undefined;
      data.dataa = {
        username: data.FormData.username,
        start_time: stDate,
        end_time: endDate,
        page:data.currentPage
      };
      data.loading = true;
      report_accountcount(data.dataa).then((res) => {
        console.log(res, "分账明细");
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            data.tableData = res.data;
            data.tableData.forEach((a)=>{
              a.total_sum = Number(a.total_sum )
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            })
            data.total = res.count_data;
            data.tableData1 = res;
            data.tableData1.sum_money = Number(res.sum_money)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };
    const getUsername = () => {
      const username1 = localStorage.getItem("username");
      const users1 = [];
      operator_data().then((res) => {
        console.log(res, "sssssss");
        data.users = res;
        res.forEach((a) => {
          users1.push(a.username);
        });
        if (users1.indexOf(username1) == -1) {
          data.isadmin = true;
        } else {
          data.isadmin = false;
          data.FormData.username = username1;
        }
      });
    };
    const handleSizeChange = (val) => {
      data.pageSize = val;
    };
    const handleCurrentChange = (val) => {
      data.currentPage = val;
      getList();
      //
    };
    onMounted(() => {
      console.log(data);
      getList();
      getUsername();
    });
    return {
      ...toRefs(data),
      rounding,
      formatDate,
      searchBtn,
      getList,
      getUsername,
      handleSizeChange,
      handleCurrentChange,
      changeUser
    };
  },
};
</script>
  
  <style>
.pagination {
  display: flex;
  justify-content: space-between;
  padding: 0 67px;
  margin-top: -10px;
}

.bottomDiv {
  height: 100%;
}
.statistics {
  display: flex;
  width: 90%;
  padding-top: 15px;
  /* background: red; */
  margin: 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
}
.statistics1 {
  display: flex;
  width: 90%;
  margin: 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
}
.statistics1 > div > a {
  text-decoration: none;
  color: black;
}
.s_title {
  font-size: 14px;
}
.s_title .el-icon {
  float: right;
  color: red;
}
.p_bottom .el-icon {
  float: right;
  color: green;
}
.s_water {
  line-height: 40px;
  font-size: 19px;
  /* font-weight: bold; */
}
.s_profress {
  line-height: 25px;
}
.statistics > div {
  width: 16%;
  margin: 10px auto;
  background: #f0f2f5;
  /* border: 1px solid red; */
  padding: 10px 20px;
  border-radius: 5px;
  /* height: 200px; */
}
.statistics > div > .el-progress {
  width: 45%;
}
.el-progress /deep/ .el-progress-bar__outer {
  --el-border-color-lighter: #fff;
}
.statistics1 > div {
  width: 13%;
  margin: 10px auto;
  /* border: 1px solid red; */
  padding: 10px;
  /* height: 200px; */
}

.BtnSearch {
  float: left;
  width: 60px;
  margin: 0 4px;
}
.pagination {
  display: flex;
  justify-content: space-between;
  padding: 0 67px;
  margin-top: -10px;
}
.flex1 {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  /* margin-top: -10px; */
}
.flex1 p {
  font-size: 14px;
  margin-right: 15px;
}
.flex1 span {
  color: red;
}
</style>
  
  
  
  
  
  
  
  